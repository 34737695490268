import React from 'react'
import NavbarMenu from './NavbarMenu'

export default function StaticNavbar() {
  return (
    <div className='z-40 fixed top-0 left-0 w-full h-12 md:h-16 lg:h-24 bg-VividRed'>
      <NavbarMenu/>
    </div>
  )
}
