import React from 'react'
import StaticNavbar from '../../component/navbar/StaticNavbar'
import Footer from '../../component/footer/Footer'
import SpecialOfferDetalis from '../../component/specialOffer/SpecialOfferDetalis'

export default function SpecialOfferPage() {
  return (
    <div>
        <StaticNavbar/>
        <SpecialOfferDetalis/>
        <Footer/>
    </div>
  )
}
